export const ADD_TO_CART= 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const DECREASE_QNTY = "DECREASE_QNTY"
export const EMPTY_CART = 'EMPTY_CART';
export const GEL_AL ="GEL_AL";
export const USE_POINTS ="USE_POINTS";
export const GET_CART_IDS ="GET_CART_IDS";
export const GET_TOTAL="GET_TOTAL";

export const setGel_al = item => dispatch =>{
    dispatch(
        {
            
            type: GEL_AL,
            payload: item
        }
    )
}
export const setUsepoints = item => dispatch =>{
    dispatch(
        {
            
            type: USE_POINTS,
            payload: item
        }
    )
}

export const AddToCart = item => dispatch =>{
    dispatch(
        {
            
            type: ADD_TO_CART,
            payload: item
        }
    )
}

export const Decrease_Qnty = id => dispatch =>{
    dispatch(
        {
            type: DECREASE_QNTY,
            payload: id
        }
    )
}

export const RemoveItem = id => dispatch =>{
    dispatch(
        {
            type: REMOVE_FROM_CART,
            payload: id
        }
    )
}

export const EmptyCart = () => dispatch =>{
    dispatch(
        {
            type: EMPTY_CART,
        }
    )
}
export const getIds = () => dispatch =>{
    dispatch(
        {
            type: GET_CART_IDS,
        }
    )
}
export const getTotal = () => dispatch =>{
    dispatch(
        {
            type: GET_TOTAL,
        }
    )
}


