import React from 'react'
import {TailSpin} from 'react-loader-spinner'

export default function Loading() {
  return (
    <div className='spinner-cont'>
        <TailSpin
        height="80"
        width="80"
        color="#FFA166"
        ariaLabel="tail-spin-loading"
        radius="1"
        visible={true}
        />     
    </div>
  )
}
