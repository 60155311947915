import React from 'react'
import { useNavigate } from 'react-router-dom'
import { NavBar } from '../../components/NavBar';

export const Forgot_failed = () => {
   let navigate = useNavigate();
   return (
        <>
        <NavBar/>
        <div className="wrapper">
           <div className='form-wrapper'>
              <h4 className='mb-5'> İstek başarısız oldu, daha sonra tekrar deneyin </h4>
                 <div className='submit'>
                    <button onClick={()=>{ navigate('/home');}} className='submit-btn'>Ana sayfaya gidin</button>
                 </div>
           </div>
        </div>
     </>
  )
}
