import React from 'react'
import { useState,useEffect } from 'react';
import "../loginandsignup.css";
import { NavBar } from '../../components/NavBar';
import { domain } from '../../domain';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

const ForgotForm = () => {
   const [password,setPassword] = useState("");
   const [error,seterror] = useState(false);

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const [show, setShow] = useState(false);
   const [message,setMessage] = useState("");

   const { uidb64,token } = useParams();
   let navigate = useNavigate();
   useEffect(() => {
      if(localStorage.getItem('access-token') !== null){
         navigate('/home');
      }
   },[]);

   const handlesubmission = () =>{


      if(password.length>=8){
         var formdata = new FormData();
         formdata.append("password", password);
         formdata.append("token", token);
         formdata.append("uidb64", uidb64);
   

         var requestOptions = {
         method: 'PATCH',
         body: formdata,
         redirect: 'follow'
         };
   
         fetch(`${domain}/api/auth/password-reset-complete/${uidb64}/${token}/`, requestOptions)
         .then(response => response.text())
         .then(result => {
            var res = JSON.parse(result)
            if (res.success !=undefined){
               setMessage("şifrenizi sıfırlandi");
               seterror(false)
            }
            else{
               setMessage("Beklenmeyen bir hata oluştu");
               seterror(true)
            }
            handleShow();
         })
         .catch(error => {
            setMessage("Beklenmeyen bir hata oluştu Sonuç bulunamadı");
            seterror(true)
            handleShow();
         });
      }
   }
   
   return (
         <>
         <NavBar/>
         <div className='wrapper'>
            <div className='form-wrapper'>
               <h2>Yeni Şifre</h2>
               <div  noValidate >
                  
                  <div className='password'>
                        <label htmlFor="password">Yeni Şifre:</label>
                        <input type='password' value={password} name='password' onChange={(e)=> setPassword(e.target.value)}/>
                        { password.length < 8 &&  <span style={{color: "red"}}>{"Şifre 8 karakterden oluşmalıdır"}</span>}
                  </div>    
                  <div className='submit'>
                     <button  className='submit-btn' onClick={handlesubmission}> Gönder</button>
                  </div>
            </div>
            </div>
         </div>
         <Modal
         show={show}
         onHide={handleClose}
         backdrop="static"
         keyboard={false}
         >
         <Modal.Header>
            <Modal.Title>Dikkat!</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            {message}
         </Modal.Body>
         <Modal.Footer>
            <button onClick={()=>{
               handleClose();
               if(error){
                  navigate('/forgot');
               }
               else{
                  navigate('/home');
               }
               }} className="check-btn">Anladım</button>
         </Modal.Footer>
         </Modal>   
         </>
   )
}
export default ForgotForm;