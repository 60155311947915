import React from 'react';
import { Footer } from '../../components/Footer';
import { NavBar } from '../../components/NavBar';
import "./home.css";
import leftimg from "../../assets/left.png";
import Responsive from "../../assets/Background_Responsive.png";
import rightimg from"../../assets/right.png";
import Button from 'react-bootstrap/Button';

export const Home = () => {
  return (
    <div className='bedan'>
        <NavBar/>
          <div className="row hero m-0">
            <div className="not-Responsive clearfix col-md-8 col-lg-8 col-xl-8 p-0 pt-3 m-0 mb-2">
              <img src={leftimg} className="m-0 mt-3 h-auto w-100 img-fluid " alt="..."/>
            </div>
            <div className="Responsive clearfix col-md-8 col-lg-8 col-xl-8 p-0 m-0 mb-2">
              <img src={Responsive} className="m-0 h-auto w-100 img-fluid " alt="..."/>
            </div>
            <div className="clearfix col-md-4 col-xl-4 right-img ">
              <div className='row m-0'>
              <img src={rightimg} className="m-0 small-image w-100 img-fluid " alt="..."/>
              </div>
              <div className='row m-0 px-3 pb-3'>
                <Button className="login" variant="outline-dark" href='/menu'>Hemen Sipariş ver</Button>{' '}
              </div>
            </div>
          </div>
        <Footer></Footer>
    </div>
  )
}
