// Reducers
import {FETCH_ORDER_FAILURE,FETCH_ORDER_SUCCESS,FETCH_ORDER_REQUEST,FETCH_ORDER_HISTORY_FAILURE,FETCH_ORDER_HISTORY_REQUEST,FETCH_ORDER_HISTORY_SUCCESS} from '../actions/orders'

const OrdersReducer = (state = { orders: [],order_history:[], loading_orders: true,loading_history: true, error_order: null,error_history:null  }, action) => {
    switch (action.type) {
      case FETCH_ORDER_REQUEST:
        return { ...state, loading_orders: true };
      case FETCH_ORDER_SUCCESS:
        return { ...state, orders: action.payload, loading_orders: false };
      case FETCH_ORDER_FAILURE:
        return { ...state, error: action.payload, loading_orders: false };
      case FETCH_ORDER_HISTORY_REQUEST:
        return { ...state, loading_history: true };
      case FETCH_ORDER_HISTORY_SUCCESS:
        return { ...state, order_history: action.payload, loading_history: false };
      case FETCH_ORDER_HISTORY_FAILURE:
        return { ...state, error_history: action.payload, loading_history: false };
      default:
        return state;
    }
  };
export default OrdersReducer;