import React, { useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import "./profilemenu.scss";
import { getUserinfo, signout } from '../statemanagement/actions/account';
import {useSelector,useDispatch} from 'react-redux';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a className='drop'
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      Hosgeldiniz 
      &#x25bc;
    </a>
  ));
  

const Profilemenu =() => {
  const dispatch = useDispatch();
  const {user_info,loaded,updated} = useSelector(state => state.AccountReducer);

  useEffect(() => {
      dispatch(getUserinfo(localStorage.getItem("access-token")));
      if(loaded){
      }
  }, [])
  
    return (
        <>
        
            <div className='me-5 ps-2 sidemenu'>  
                <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                 </Dropdown.Toggle>
                    <Dropdown.Menu className='w-25'>
                        <Dropdown.Item href="/account">Hesabım</Dropdown.Item>
                        <Dropdown.Item href="/address">Adresim</Dropdown.Item>
                        <Dropdown.Item href="/pastpoints">Geçmiş Puanları</Dropdown.Item>
                        <Dropdown.Item href="/orders">Geçmiş Siparişler</Dropdown.Item>
                        <Dropdown.Item onClick={()=>{dispatch(signout(localStorage.getItem("access-token"),localStorage.getItem("refresh-token")))}}>Oturumu Kapat</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <div className='mt-2  puan'>
                  <i className="fas fa-coins fa-lg "></i> Toplam <strong className='points'> {loaded ? user_info[0][1] :" " }</strong>  Puan
                </div>
            </div>
            <div>

            </div>
            
        </>
    )
}

export default Profilemenu