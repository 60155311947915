import React, { useEffect } from 'react'
import { Footer } from '../../components/Footer';
import { NavBar } from '../../components/NavBar';
import Tab from 'react-bootstrap/Tab';
import Navtab from '../../components/navtab';
import { useState } from 'react';
import {useNavigate } from 'react-router-dom';
import { domain } from '../../domain';
const Points =() => {
  const [table,setTable] = useState("");
  const navigate = useNavigate();
  const [flag,setFlag] = useState(false);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortKey, setSortKey] = useState('date');



  function formatDate(date, locales, options) {
    return new Date(date).toLocaleString(locales, options);
  }

  function sortByDateDescending(list, dateProp) {
    return list.sort((a, b) => {
      const dateA = new Date(a[dateProp]);
      const dateB = new Date(b[dateProp]);
      return dateB - dateA;
    });
  }
  async function getpoints(){
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("access-token")}`);
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      
      await fetch(`${domain}/api/account/points`, requestOptions)
        .then(response => response.text())
        .then(result =>{
          const obj = JSON.parse(result);
          const sortedListDescending = sortByDateDescending(obj.transactions, "date");
          setTable(obj.transactions);
          setFlag(true);
        })
        .catch(error => console.log('error', error));
  }
  useEffect(() => {    

    if(localStorage.hasOwnProperty("access-token") === false ){
      navigate('/home');
    }
    else{
      getpoints();
      
    }
  },[flag]);  
  return (
    <>
    <NavBar/>
    <div className='Address'>
      <Tab.Container id="left-tabs-example" defaultActiveKey="fourth">
        <div className='row m-0 my-5'>
            <div className=" blyat col-sm-12 col-md-4">
                <Navtab/>
            </div>
            <div className='a7a-col col-sm-12 col-md-8'>
              <div className='ps-3 pt-3 row'>
                  <h1 className=' col-8 heading'>
                      Puan Geçmişi
                  </h1>
              </div>
              <div className='ps-3 pt-3 row'>
                <table className="table">
                  <thead className='table-dark'>
                    <tr>
                      <th scope="col">Puan Türü</th>
                      <th scope="col">Miktar</th>
                      <th scope="col">Durum	</th>
                      <th scope="col">Tarih</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      !flag ? <></>
                      :
                      table.map((e)=>
                      <tr>
                        <th>{e.type}</th>
                        <td>{e.amount}</td>
                        <td>{e.status}</td>
                        <td>{formatDate(e.date,"en-US", {
  dateStyle: "short",
  timeStyle: "medium"})}</td>
                      </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>
        </div>    
      </Tab.Container>
    </div>
      <Footer/>
    </>
  )
}

export default Points
