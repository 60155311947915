import {applyMiddleware,combineReducers} from 'redux';
import thunk from 'redux-thunk';
import {legacy_createStore as createStore} from 'redux';
import CartReducer from './reducers/cart';
import AdressReducer from './reducers/adress';
import AccountReducer from './reducers/account';
import ProductsReducer from './reducers/products';
import OrdersReducer from './reducers/orders';


const rootReducer = combineReducers({CartReducer,AdressReducer,AccountReducer,ProductsReducer,OrdersReducer});

export const Store = createStore(rootReducer, applyMiddleware(thunk));