import React from 'react'
import { useNavigate } from 'react-router-dom'
import { NavBar } from '../../components/NavBar';

export default function Verify_Success() {
   let navigate = useNavigate();
   return (
        <>
        <NavBar/>
        <div className="wrapper">
           <div className='form-wrapper'>
              <h3 className='mb-5'> Hesabınız başarıyla doğrulandı</h3>
                 <div className='submit'>
                    <button onClick={()=>{ navigate('/home')}} className='submit-btn'>Ana sayfaya gidin</button>
                 </div>
           </div>
        </div>
     </>
  )
}
