import React, { useEffect, useState } from 'react';
import { AddToCart } from '../statemanagement/actions/cart';
import "./foodcard.css";
import {useSelector,useDispatch} from 'react-redux';
require.context('../assets', false, /\.(png|jpe?g|svg)$/);

const Foodcard = (item) => {
  const [food,setFood] = useState({});
  const [images,setImages] = useState({});

  useEffect(() => {
    setFood(item.item);
    if(item.item.type === "Sushi Sets"){
      const sushisets = importAll(require.context("../assets/Sushi Sets", false, /\.(png|jpe?g|svg)$/));
      setImages(sushisets);

    }
    else if(item.item.type === "Sushi Rolls"){
      const sushirolls = importAll(require.context("../assets/Sushi Rolls", false, /\.(png|jpe?g|svg)$/));
      setImages(sushirolls)
    }
    else if(item.item.type === "Nigiri"){
      const Nigiri = importAll(require.context("../assets/Nigiri", false, /\.(png|jpe?g|svg)$/));
      setImages(Nigiri);

    }
    else if(item.item.type === "Snacks"){
      const Snacks = importAll(require.context("../assets/Snacks", false, /\.(png|jpe?g|svg)$/));
      setImages(Snacks);

    }
    else if(item.item.type === "Korean Fried Chicken"){
      const KFC = importAll(require.context("../assets/Korean Fried Chicken", false, /\.(png|jpe?g|svg)$/));
      setImages(KFC);
    }
    else if(item.item.type === "Yaki Sushi"){
      const YAKI = importAll(require.context("../assets/Yaki Sushi", false, /\.(png|jpe?g|svg)$/));
      setImages(YAKI);
    }
    else if(item.item.type === "Noodles"){
      const Noodles = importAll(require.context("../assets/Noodles", false, /\.(png|jpe?g|svg)$/));
      setImages(Noodles);
    }
    else if(item.item.type === "Drinks"){
      const Drinks = importAll(require.context("../assets/Drinks", false, /\.(png|jpe?g|svg)$/));
      setImages(Drinks);
    }
    else if(item.item.type === "Çorba/Soup"){
      const Drinks = importAll(require.context("../assets/Soup", false, /\.(png|jpe?g|svg)$/));
      setImages(Drinks);
    }
  },[]);



  const dispatch = useDispatch();

  function importAll(r) {
        let images = {};
         r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
        return images
       }
  return (
    <div className="card mb-3">
      <div className="row">
        <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
          <img  className="image-food px-1 py-1 d-block w-100" src={images[`${food.src}.jpg`]} alt=""/>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div className="card-block  pt-1">
            <p className='title'>{food.name ? food.name : "Food"}</p>
            <p className='pe-0 description'> {food.description ? 
              food.description.length > 190 ? (food.description.substring(0,190) + "...."):
              food.description : "Description"}
              </p>
          </div>
        </div>
        <div className="col-xl-3 pt-1 col-lg-2 col-md-12 col-sm-12 p-0 pe-2">
        <p className='price'>{food.price ? food.price  : "100"}{" ₺"}</p>
          <div className=' price-div'>
            <a className='btn mb-3 w-75 login' onClick={()=> dispatch(AddToCart(food))}> Ekle</a>
          </div>

        </div>
    </div>
    </div>
  )
}

export default Foodcard;