import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Provider as ReduxProvider} from 'react-redux';
import { Store } from './statemanagement/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ReduxProvider store ={Store}>
  <App />
  </ReduxProvider>
);
