// Reducers
import {FETCH_ITEMS_FAILURE,FETCH_ITEMS_SUCCESS,FETCH_ITEMS_REQUEST} from '../actions/products'

const ProductsReducer = (state = { products: [], loading: true, error: null }, action) => {
    switch (action.type) {
      case FETCH_ITEMS_REQUEST:
        return { ...state, loading: true };
      case FETCH_ITEMS_SUCCESS:
        return { ...state, products: action.payload, loading: false };
      case FETCH_ITEMS_FAILURE:
        return { ...state, error: action.payload, loading: false };
      default:
        return state;
    }
  };
export default ProductsReducer;