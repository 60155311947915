import {UPDATE_INFO,RESET_EMAIL,RESET_PASS,GET_INFO} from "../actions/account";

const initialState ={
    user_info: [],
    loaded: false,
}


function AccountReducer(state=initialState,action){
    switch(action.type){
         case UPDATE_INFO:
             return {
                 ...state,
                 loaded:false,
             };
         case RESET_EMAIL:
             return {
                 ...state,
                 loaded:false
             };
         case RESET_PASS:
             return{
                 ...state,
                 loaded:false
         };
         case GET_INFO:
             return{
                 ...state,
                 user_info:action.payload,
                 loaded: true
         };
         default:
             return state;
    }
 }
 export default AccountReducer;