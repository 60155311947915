import React, { useEffect } from 'react'
import { Footer } from '../../components/Footer';
import { NavBar } from '../../components/NavBar';
import Tab from 'react-bootstrap/Tab';
import Navtab from '../../components/navtab';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {useSelector,useDispatch} from 'react-redux';
import { getUserinfo,resetPass,updateInfo } from '../../statemanagement/actions/account';
import Modal from 'react-bootstrap/Modal';
import Loading from '../../components/Loading';
import "./account.css";
const Account = () => {
  let navigate = useNavigate();
  const {user_info,loaded,updated} = useSelector(state => state.AccountReducer);
  const dispatch = useDispatch();
  const [error,setError] = useState(false);
  const [name,setName] = useState("");
  const [password,setPassword] = useState("");
  const [confirmpassword,setConfirmPassword] = useState("");
  const [email,setEmail] = useState("");
  const [phone,setPhone] = useState("");
  const [points,setPoints] = useState("");
  const [date,setDate] = useState("");
  const [message,setMessage] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    if(localStorage.getItem('access-token') === null){
      navigate('/home');
    }
    else{
      dispatch(getUserinfo(localStorage.getItem("access-token")));
      if(loaded){
        setName(user_info[0][0])
        setPoints(user_info[0][1])
        setPhone(user_info[0][2])
        setDate(user_info[0][3])
        setEmail(user_info[0][4])
      }
    }
  }, [loaded])
  const handleClose = () => {
    setShow(false);
    window.location.reload(false);
 }
 const handleShow = () => setShow(true);

  //Replace numbers with letters
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };

    
 
  return (
    <div>
    <NavBar/>
    <div className='Address'>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <div className='row m-0 my-5'>
            <div className=" blyat col-sm-12 col-md-4">
                <Navtab/>
            </div>
            <div className='a7a-col col-sm-12 col-md-8'>
            {
               !loaded ? <>
               <Loading/>
               </>
              :
                <>
                  <div id='menu' className='add-col' >
                      <div className='ps-3 pt-3 row'>
                          <h1 className=' col-8 heading'>
                              Hesab Bilgilerim
                          </h1>
                          <div className='col-1'></div>
                      </div>
                          <div className="row ps-3 pt-1">
                            <div className='fullName col-lg-4 col-md-6'>
                              <label htmlFor="fullName">Ad Soyad:</label>
                              <input type='text' value={name} name='fullName' onChange={(e) =>setName(e.target.value)}/>
                              {error && name.length === 0 &&  <span style={{color: "red"}}>{"İsim boş bırakılamaz"}</span>}
                            </div>
                            <div className=' col-lg-4 col-md-6'>
                              <label htmlFor="phone">Cep Telefonu:</label>
                              <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">+90</span>
                                </div>
                                <input type="number"value={phone}     onInput={(e) => {if (e.target.value.length > 10) e.target.value = e.target.value.slice(0, 10)}}
                                    name='phone' onChange={(e)=> setPhone(e.target.value)} className="form-control"  aria-label="Phone" aria-describedby="basic-addon1"/>
                              </div>
                              {error && phone.length < 10 &&  <span style={{color: "red"}}>{"Telefon numarası 10 sayıdan oluşmalıdır"}</span>}
                            </div>
                            <div className=' col-lg-4 col-md-6'>
                              <label htmlFor="phone">Doğum Tarihi:</label>
                              <div className="input-group mb-3">
                                <input type="text" value={date} disabled className="form-control"  aria-label="Phone" aria-describedby="basic-addon1"/>
                              </div>
                            </div>
                            <div className=' col-lg-4 col-md-6'>
                              <div className="input-group mb-3">
                                <button onClick={()=>{
                                    dispatch(updateInfo(localStorage.getItem("access-token"),phone,name));
                                    setMessage("Kişisel bilgilerinizi güncelledik!");
                                    setShow(true);
                                }} className='btn w-100 btn-outline-dark'>Güncelle</button>
                              </div>
                            </div>
                          </div>
                  </div>
                  {/* Cuzdan */}
                  <div id='menu' className='add-col' >
                      <div className='ps-3 pt-1 row'>
                          <h1 className=' col-8 heading'>
                            Cüzdan  
                          </h1>
                          <div className='col-1'></div>
                      </div>
                      <div className='ps-3 pt-1 row'>
                        <div className=' col-lg-4 col-md-6'>
                            <label htmlFor="phone">Puan</label>
                            <div className="input-group mb-3">
                              <input type="text" value={points} disabled className="form-control"  aria-label="Phone" aria-describedby="basic-addon1"/>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <label htmlFor="phone">Bakiye </label>
                            <div className="input-group mb-3">
                              <input type="text" value={points} disabled className="form-control"  aria-label="Phone" aria-describedby="basic-addon1"/>
                            </div>
                          </div>
                      </div>
                  </div>
                  {/* Password Reset */}
                  <div id='menu' className='add-col' >
                      <div className='ps-3 pt-1 row'>
                          <h1 className=' col-8 heading'>
                          Şifre Değiştir  
                          </h1>
                          <div className='col-1'></div>
                      </div>
                      <div className='ps-3 pt-1 row'>
                        <div className=' col-lg-4 col-md-6'>
                            <label htmlFor="phone"> Yeni Şifre: </label>
                            <div className="input-group mb-3">
                            <input type='password' value={password} 
                             onChange={(e)=> setPassword(e.target.value)}/>
                              {error && password.length < 8 &&  <span style={{color: "red"}}>{"Şifre 8 karakterden oluşmalıdır"}</span>}   
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <label htmlFor="phone">Yeni Şifre (tekrar): </label>
                            <div className="input-group">
                              <input type='password' value={confirmpassword} 
                               onChange={(e)=> setConfirmPassword(e.target.value)}/>
                              {error  && (password.length<8 || confirmpassword) !== password &&  <span style={{color: "red"}}>{"Yeni şifreniz ve onaylanan şifreniz aynı olmalıdır!"}</span>}   
                            </div>
                        </div>
                      </div>
                      <div className='row ps-3'>
                        <div className='col-4'>
                            <div className="input-group mb-3">
                                  <button onClick={()=>{
                                      if(password.length >= 8 && password === confirmpassword){
                                        setError(false)
                                        dispatch(resetPass(localStorage.getItem("access-token"),password));
                                        setMessage("şifrenizi değiştirdik!");
                                        setShow(true);
                                      }
                                      else{
                                        setError(true)
                                      }
                            }} className='w-100 btn btn-outline-dark'>Değiştir</button>
                            </div>
                            </div>
                      </div>


                  </div>
                </>
            }
            </div>
        </div>    
      </Tab.Container>
    </div>
      <Footer/>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
      <Modal.Header>
          <Modal.Title>Dikkat!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         {message}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={()=>handleClose()} className="check-btn">Anladım</button>
        </Modal.Footer>
      </Modal>   
    </div>
    )
};

export default Account;