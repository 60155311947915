import React from 'react'
import { NavBar } from '../../components/NavBar';
import { Footer } from '../../components/Footer';
import Col from 'react-bootstrap/Col';
import "./Menu.css"
import Foodcard from '../../components/foodcard';
import { useEffect } from 'react';
import { fetchProducts } from '../../statemanagement/actions/products';
import {useSelector,useDispatch} from 'react-redux';
import Loading from '../../components/Loading';
import { Cart } from '../../components/Cart';
const Menu = () => {

    const dispatch = useDispatch();
    const {products,loading} = useSelector(state => state.ProductsReducer);

    useEffect(() => {
        dispatch(fetchProducts());

    },[]);



    return (
        <div>
            <div className='sticky'>
            <NavBar/>
            <div className="scrollmenu ">
                        <a href="#menu">SUSHİ SETLER</a>
                        <a href="#rolls">SUSHİ ROLLS</a>
                        <a href="#soup">ÇORBA</a>
                        <a href="#yaki">YAKİ SUSHİ </a>
                        <a href="#ks">KOREAN CHİCKEN</a>
                        <a href="#noodles">NOODLE</a>
                        <a href="#sn">SUSHİ NİGİRİ</a>
                        <a href="#snacks">SNACKS</a>
                        <a href="#drinks">İÇECEKLER</a>
            </div>
            </div>

            {
                loading ? <Loading/> :
                <>
                    <div className='menu-row  px-3 p-5 row m-auto'>
                        {/* Menu */}
                        <Col className='extraspace' lg={7} md={7} sm={7} xs={12} >
                        <div id='menu' className='menu-col p-3 m-3' >
                            <h1 className='pt-3 heading'
                            >
                                Sushi Setler
                            </h1>
                            <div>
                                {products.filter((item) => item.type === "Sushi Sets").map((item) => (
                                    <Foodcard item={item}></Foodcard>

                                ))}
                            </div>
                        </div>
                        <div id='rolls' className='pt-5 mt-5'></div>
                        <div className='menu-col p-3 m-3'>
                            <h1 className='pt-3 heading'
                            >
                                Sushi Rolls
                            </h1>
                            {products.filter((item) => item.type === "Sushi Rolls").map((item) => (
                                    <Foodcard item={item}></Foodcard>
                                ))}
                        </div>
                        <div id='soup' className='pt-5 mt-5'></div>
                        <div className='menu-col p-3 m-3'>
                            <h1 className='pt-3 heading'
                            >
                                Çorba Çeşitleri
                            </h1>
                            {products.filter((item) => item.type === "Çorba/Soup").map((item) => (
                                    <Foodcard item={item}></Foodcard>
                                ))}
                        </div>

                        <div id='yaki' className='pt-5'></div>
                        <div className='menu-col p-3 m-3'>
                            <h1 className='pt-3 heading'
                            >
                                Yaki Sushi
                            </h1>
                            {products.filter((item) => item.type === "Yaki Sushi").map((item) => (
                                    <Foodcard item={item}></Foodcard>

                                ))}
                        </div>
                        <div id='ks' className='pt-5'></div>             
                         <div  className='menu-col p-3 m-3'>
                            <h1 className='pt-3 heading'
                            >
                                Korean Chicken
                            </h1>
                            <div>
                                {products.filter((item) => item.type === "Korean Fried Chicken").map((item) => (
                                    <Foodcard item={item}></Foodcard>

                                ))}
                            </div>
                        </div>
                        <div id='noodles' className='pt-5'></div>
                        <div className='menu-col p-3 m-3'>
                            <h1 className='pt-3 heading'
                            >
                                Noodles
                            </h1>
                            {products.filter((item) => item.type === "Noodles").map((item) => (
                                    <Foodcard item={item}></Foodcard>

                                ))}
                        </div>
                        
                        <div id='sn' className='pt-5'></div>
                        <div className='menu-col p-3 m-3'>
                            <h1 className='pt-3 heading'
                            >
                                Sushi Nigiri
                            </h1>
                            <div>
                                {products.filter((item) => item.type === "Nigiri").map((item) => (
                                    <Foodcard item={item}></Foodcard>

                                ))}
                            </div>
                        </div>
                     
                        <div id='snacks' className='pt-5'></div>
                        <div className='menu-col p-3 m-3'>
                            <h1 className='pt-3 heading'
                            >
                                Snacks
                            </h1>
                            {products.filter((item) => item.type === "Snacks").map((item) => (
                                    <Foodcard item={item}></Foodcard>

                                ))}
                        </div>


                        <div id='drinks' className='pt-5'></div>
                        <div className='menu-col p-3 m-3'>
                            <h1 className='pt-3 heading'
                            >
                                İçecekler
                            </h1>
                            {products.filter((item) => item.type === "Drinks").map((item) => (
                                    <Foodcard item={item}></Foodcard>

                                ))}
                        </div>
                        </Col>
                        <Col id='cart' className='mt-3' lg={5} md={5} sm={5}>
                            <Cart/>
                        </Col>

                        <a id="back-to-top" href="#cart" className="btn login btn-lg back-to-top" role="button"><i className="fas fa-cart-plus fa-xs  "></i></a>

                    </div>
                </>
            }
            
            <Footer/>
        </div>
    )
}
export default Menu;