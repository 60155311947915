import React, { useEffect } from 'react';
import "./cart.css";
import {useSelector,useDispatch} from 'react-redux';
import { AddToCart,RemoveItem,Decrease_Qnty,EmptyCart, setGel_al, setUsepoints } from '../statemanagement/actions/cart';
import { useNavigate } from 'react-router-dom';
import { fetchOrders } from '../statemanagement/actions/orders';

export const Cart = (Checkout) => {

    const {cart,gel_al,total,use_points} = useSelector(state => state.CartReducer);
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const {user_info,loaded} = useSelector(state => state.AccountReducer);
    const label = "Puan Kullan";
    const {orders} = useSelector(state => state.OrdersReducer);

    const handleCheckboxChange = () => {
      dispatch(setUsepoints(!use_points));
    };
    useEffect(() => {
        if(localStorage.getItem('access-token')){
            dispatch(fetchOrders(localStorage.getItem('access-token')));
        }
    }, [])
    
    const RenderTotal = () =>{
        if(loaded){
            var result = 0;
            if( use_points && user_info[0][6]){
                result =total.toFixed(0) - user_info[0][1]- (total.toFixed(0)*0.2);
            }
            else if(use_points && !user_info[0][6]){
                result = total.toFixed(0) - user_info[0][1];
            }
            else if(!use_points && !user_info[0][6]){
                result =total.toFixed(0);

            }
            else if(!use_points && user_info[0][6]){
                result = total.toFixed(0) - (total.toFixed(0)*0.2);
            }
            if(result<0){
                return 0;
            }
            else{
                return result;
            }
        }
        else{
            return total.toFixed(0);
        }
    }
    return (            
        <div className={'menu-col sticky-cart '}>
            {
                cart.length === 0 ? 
                    <table className="table cart-table mb-0">
                        <tbody>
                            <tr>
                                <td colSpan="6">
                                    <div className="cart-empty">
                                        <i className="fa fa-shopping-cart"></i>
                                        <p>Sepetiniz boş</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table> 
                    :
                    <div className="table-responsive-xl">
                            <table  className="card table cart-table mb-0">
                                <div className="card-header bg-dark p-3">
                                    <div className="card-header-flex">
                                        <h5 className="text-white m-0"> Sepetim {cart.length > 0 ? `(${cart.length})` : ''}</h5>
                                        {
                                            cart.length > 0 ? <button className="btn btn-danger mt-0 btn-sm" onClick={() =>dispatch(EmptyCart())}><i className="fa fa-trash-alt mr-2"></i><span>Empty Cart</span></button> : ''}
                                    </div>
                                    <div className="card-header-flex">
                                        <div>
                                            <input type={"radio"} onChange={()=> dispatch(setGel_al(false))} value={gel_al} checked={gel_al === false}/>
                                            <label className='ms-2 text-light'> Adrese Teslim</label>
                                        </div>
                                        <div>
                                            <input type={"radio"} onChange={()=> dispatch(setGel_al(true))} checked={gel_al === true}/>
                                            <label className='ms-2 text-light'> Gel Al</label>
                                        </div>
                                        <div></div>
                                    </div>
                                </div>
                                <tbody>
                                {
                                    cart.map((data) => {
                                        const {  name, price, quantity,id } = data;
                                        return (
                                            <tr key={id}>
                                                <td colSpan="3"><div className="product-name"><p>{name}</p></div></td>
                                                <td colSpan="3">
                                                    <div className="prdct-qty-container">
                                                        <button className="prdct-qty-btn" type="button" onClick={() => dispatch(Decrease_Qnty(id))}>
                                                            <i className="fa fa-minus"></i>
                                                        </button>
                                                        <input type="text" name="qty" className="qty-input-box" value={quantity} disabled />
                                                        <button className="prdct-qty-btn" type="button" onClick={() => dispatch(AddToCart(data))}>
                                                            <i className="fa fa-plus"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                                <td colSpan={3}>{price * quantity}₺</td>
                                            </tr>
                                            
                                        )
                                    })
                                }     
                                <tr>
                                 <td colSpan={7} className="totalpointdiv border-top overflow-hidden">
                                        <div className='container'>
                                        <div className="row p-0">
                                            <div className="col-1 pr-ltr-0">
                                                <i className="fas fa-coins fa-lg "></i>
                                            </div>
                                            <div className="col-10 ">
                                                <label className="ms-2 text-light rewardpoint">Toplam <strong>{loaded ? user_info[0][1] :" " }</strong> puanınız var.</label>
                                            </div>
                                        </div>
                                        <div className="row pe-0">
                                            <div className="col-12 form-check ms-3">
                                            <input
                                                    id="checkbox"
                                                    className='form-check-input'
                                                    type="checkbox"
                                                    checked={use_points}
                                                    onChange={handleCheckboxChange}
                                                />
                                            <label htmlFor="checkbox" className='form-check-label text-light'>
                            
                                                {label}
                                                </label>
                                            </div>
                                        </div>
                                        </div>
                                </td>
                                </tr>           
                                <tr>
                                    <th  colSpan="3" className="text-left w-100">
                                        Toplam<span className="ml-2 mr-2">:</span>
                                        <span className="text-danger"> {loaded ? RenderTotal() : 0}₺</span>
                                    </th>
                                    <th className='a7a' colSpan="4">
                                        <button className="  mt-0 check-btn" onClick={ ()=>{

                                            if(Checkout.Checkout){
                                                if(orders.orders.length>0){
                                                    navigate('/orders');
                                                }
                                                else{
                                                    Checkout.Checkoutfun();
                                                }
                                            }
                                            else{
                                                if(localStorage.getItem('access-token')){
                                                    if(orders.orders.length>0){
                                                        navigate('/orders');
                                                    }
                                                    else{
                                                        navigate('/checkout');
                                                    }
                                                }
                                                else{
                                                    navigate('/login');
                                                }
                                            }
                                        }}
                                        > <span>{Checkout?"Sipariş Ver":"Kasaya Git"}</span></button>
                                    </th>
            
                                </tr>

            
                                </tbody>
            
                            </table>
                    </div>
            }
        </div>
    )

}
